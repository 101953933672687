import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: 'Bugster.ai',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='overflow-hidden'>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
            <nav className='relative z-10 px-9 pt-8 bg-black h-full overflow-y-auto'>
              <div className='flex flex-wrap justify-between h-full'>
                <div className='w-full'>
                  <div className='flex items-center justify-between -m-2'>
                    <div className='w-auto p-2'>
                      <a className='inline-block' href='#'>
                        Bugster.ai
                      </a>
                    </div>
                    <div className='w-auto p-2'>
                      <a className='navbar-burger text-white' href='#'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M6 18L18 6M6 6L18 18'
                            stroke='currentcolor'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col justify-center py-16 w-full'>
                  <ul>
                    <li className='mb-12'>
                      <a
                        className='font-medium text-white hover:text-gray-200'
                        href='#'
                      >
                        Features
                      </a>
                    </li>
                    <li className='mb-12'>
                      <a
                        className='font-medium text-white hover:text-gray-200'
                        href='#'
                      >
                        Solutions
                      </a>
                    </li>
                    <li className='mb-12'>
                      <a
                        className='font-medium text-white hover:text-gray-200'
                        href='#'
                      >
                        Resources
                      </a>
                    </li>
                    <li>
                      <a
                        className='font-medium text-white hover:text-gray-200'
                        href='#'
                      >
                        Pricing
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='flex flex-col justify-end w-full pb-8'>
                  <div className='flex flex-wrap'>
                    <div className='w-full'>
                      <div className='block'>
                        <button
                          className='py-3 px-5 w-full text-white font-semibold border border-gray-800 hover:border-gray-600 rounded-xl focus:ring focus:ring-gray-50 bg-transparent transition ease-in-out duration-200'
                          type='button'
                        >
                          Create Free Account
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </section>
        <div className='md:inline-block'></div>
        <section className='overflow-hidden'>
          <div className='flex items-end justify-between px-7 py-5 bg-white'>
            <div className='w-auto'>
              <div className='flex flex-wrap items-center'>
                <div className='w-auto mr-14'>
                  <a href='http://bugster.ai/'>
                    <img src='images/logo.png' alt='' style={{ height: '50px' }} />
                  </a>
                </div>
              </div>
            </div>
            <div className='w-auto'>
              <div className='flex flex-wrap items-center'>
                <div className='w-auto hidden lg:block'>
                  <div className='inline-block'>
                    <a href='#howitworks' className='font-medium hover:text-gray-700 pr-10' >How it works</a>
                  </div>
                  <div className='inline-block'>
                    <a href='https://ui53ay3p2dg.typeform.com/to/u1KPi7Cn'>
                      <button
                        className='py-3 px-5 w-full font-semibold border hover:border-gray-300 rounded-xl focus:ring focus:ring-gray-50 bg-white hover:bg-gray-50 transition ease-in-out duration-200'
                        type='button'
                      >
                        Get Access Now
                      </button>
                    </a>
                  </div>
                </div>
                <div className='w-auto lg:hidden'>
                  <a href='#'>
                    <svg
                      className='navbar-burger text-indigo-600'
                      width={51}
                      height={51}
                      viewBox='0 0 56 56'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        width={56}
                        height={56}
                        rx={28}
                        fill='currentColor'
                      />
                      <path
                        d='M37 32H19M37 24H19'
                        stroke='white'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
            <nav className='relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto'>
              <div className='flex flex-wrap justify-between h-full'>
                <div className='w-full'>
                  <div className='flex items-center justify-between -m-2'>
                    <div className='w-auto p-2'>
                      <a className='inline-block' href='#'>
                        <img
                          src='flaro-assets/logos/flaro-logo-black.svg'
                          alt=''
                        />
                      </a>
                    </div>
                    <div className='w-auto p-2'>
                      <a className='navbar-burger' href='#'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M6 18L18 6M6 6L18 18'
                            stroke='#111827'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col justify-center py-16 w-full'>
                  <ul>
                    <li className='mb-12'>
                      <a className='font-medium hover:text-gray-700' href='#'>
                        Features
                      </a>
                    </li>
                    <li className='mb-12'>
                      <a className='font-medium hover:text-gray-700' href='#'>
                        Solutions
                      </a>
                    </li>
                    <li className='mb-12'>
                      <a className='font-medium hover:text-gray-700' href='#'>
                        Resources
                      </a>
                    </li>
                    <li>
                      <a className='font-medium hover:text-gray-700' href='#'>
                        Pricing
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='flex flex-col justify-end w-full pb-8'>
                  <div className='flex flex-wrap'>
                    <div className='w-full'>
                      <div className='block'>
                        <button
                          className='py-3 px-5 w-full font-semibold border hover:border-gray-300 rounded-xl focus:ring focus:ring-gray-50 bg-white hover:bg-gray-50 transition ease-in-out duration-200'
                          type='button'
                        >
                          Create Free Account
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </section>
        <section className='overflow-hidden'>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
            <nav className='relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto'>
              <div className='flex flex-wrap justify-between h-full'>
                <div className='w-full'>
                  <div className='flex items-center justify-between -m-2'>
                    <div className='w-auto p-2'>
                      <a className='inline-block' href='#'>
                        <img
                          src='flaro-assets/logos/flaro-logo-black.svg'
                          alt=''
                        />
                      </a>
                    </div>
                    <div className='w-auto p-2'>
                      <a className='navbar-burger' href='#'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M6 18L18 6M6 6L18 18'
                            stroke='#111827'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col justify-center py-16 w-full'>
                  <ul>
                    <li className='mb-12'>
                      <a className='font-medium hover:text-gray-700' href='#'>
                        Features
                      </a>
                    </li>
                    <li className='mb-12'>
                      <a className='font-medium hover:text-gray-700' href='#'>
                        Solutions
                      </a>
                    </li>
                    <li className='mb-12'>
                      <a className='font-medium hover:text-gray-700' href='#'>
                        Resources
                      </a>
                    </li>
                    <li>
                      <a className='font-medium hover:text-gray-700' href='#'>
                        Pricing
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='flex flex-col justify-end w-full pb-8'>
                  <div className='flex flex-wrap'>
                    <div className='w-full'>
                      <div className='block'>
                        <button
                          className='py-3 px-5 w-full font-semibold border hover:border-gray-300 rounded-xl focus:ring focus:ring-gray-50 bg-white hover:bg-gray-50 transition ease-in-out duration-200'
                          type='button'
                        >
                          Create Free Account
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </section>
        <section>
          <div className='container mx-auto overflow-hidden'>
            <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'>
              <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
              <nav className='relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto'>
                <div className='flex flex-wrap justify-between h-full'>
                  <div className='w-full'>
                    <div className='flex items-center justify-between -m-2'>
                      <div className='w-auto p-2'>
                        <a className='inline-block' href='#'>
                          <img
                            src='flaro-assets/logos/flaro-logo-black.svg'
                            alt=''
                          />
                        </a>
                      </div>
                      <div className='w-auto p-2'>
                        <a className='navbar-burger' href='#'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M6 18L18 6M6 6L18 18'
                              stroke='#111827'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col justify-center py-16 w-full'>
                    <ul>
                      <li className='mb-12'>
                        <a className='font-medium hover:text-gray-700' href='#'>
                          Features
                        </a>
                      </li>
                      <li className='mb-12'>
                        <a className='font-medium hover:text-gray-700' href='#'>
                          Solutions
                        </a>
                      </li>
                      <li className='mb-12'>
                        <a className='font-medium hover:text-gray-700' href='#'>
                          Resources
                        </a>
                      </li>
                      <li>
                        <a className='font-medium hover:text-gray-700' href='#'>
                          Pricing
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='flex flex-col justify-end w-full pb-8'>
                    <div className='flex flex-wrap'>
                      <div className='w-full'>
                        <div className='block'>
                          <button
                            className='py-2.5 px-4 text-base w-full font-medium border border-gray-400 hover:border-gray-500 rounded-xl focus:ring focus:ring-gray-50 bg-white hover:bg-gray-50 transition ease-in-out duration-200'
                            type='button'
                          >
                            Join free for 30 days
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <div className='overflow-hidden pb-16'>
            <div className='container px-4 mx-auto'>
              <div className='mb-16 relative bg-indigo-50 overflow-hidden rounded-3xl'>
                <img
                  className='absolute left-0 bottom-0'
                  src='flaro-assets/images/headers/gradient3.svg'
                  alt=''
                />
                <div className='relative z-20 flex flex-wrap items-end -m-8'>
                  <div className='w-full lg:w-1/2 p-20 lg:pr-0 lg:pl-28 lg:py-28'>
                    <h2 className='mb-7 text-6xl md:text-8xl xl:text-10xl font-bold font-heading tracking-px-n leading-none'>
                      Your AI Bug Fixing Companion
                    </h2>
                    <p className='mb-10 text-lg text-gray-900 font-medium'>
                      Solve Bugs at the Speed of Light!
                    </p>
                    <div className='mb-6 md:inline-block'>
                      <a href='https://ui53ay3p2dg.typeform.com/to/u1KPi7Cn'>

                        <button
                          className='py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200'
                          type='button'
                        >
                          Get Access Now
                        </button>
                      </a>
                    </div>
                    <ul></ul>
                  </div>
                  <div className='w-full lg:w-1/2 p-8'>
                    <img
                      src='images/document-1-1-jpg-1687344022205.webp'
                      alt=''
                    />
                  </div>
                </div>
              </div>
              <p className='mb-9 text-gray-900 text-center  text-5xl md:text-6xl font-bold font-heading'>
                &nbsp;Trusted by development teams at&nbsp;
              </p>
              <div className='flex flex-wrap justify-center items-center -m-8'>
                <div className='w-auto p-8'>
                  <img
                    className='img-fluid h-20'
                    src='images/wh-webp-1687353481150.webp'
                    alt=''
                  />
                </div>
                <div className='w-auto p-8'>
                  <img className='img-fluid  h-20' src='images/xoxo-removebg-preview-png-1687114499064.webp' alt='' />
                </div>
                <div className='w-auto p-8'>
                  <img className='img-fluid bg-red-800 h-20' src='images/khata-removebg-preview.png' alt='' />
                </div>
                <div className='w-auto p-8'>
                  <img className='img-fluid h-20' src='images/phonepay.png' alt='' />
                </div>
                <div className='w-auto p-8'>
                  <img className='img-fluid h-20 bg-black' src='images/cult-removebg-preview.png' alt='' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='pt-24 pb-32 bg-white overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap -m-8'>
              <div className='w-full md:w-1/2 p-8'>
                <div className='md:max-w-lg'>
                  <h2 className='mb-14 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight' id='howitworks'>
                    Get Started in three easy steps
                  </h2>
                  <div className='flex flex-wrap -m-5 mb-9'>
                    <div className='w-full p-5'>
                      <div className='flex flex-wrap -m-4'>
                        <div className='w-auto p-4'>
                          <div className='relative mb-3 w-16 h-16 text-lg text-indigo-600 font-bold border border-gray-200 rounded-full'>
                            <img
                              className='absolute top-0 left-0'
                              src='flaro-assets/images/how-it-works/gradient.svg'
                              alt=''
                            />
                            <span className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                              1
                            </span>
                          </div>
                        </div>
                        <div className='flex-1 p-4'>
                          <div className='md:max-w-xs'>
                            <h3 className='mb-3 text-xl font-semibold leading-normal'>
                              Assign Bugs to Bugster Bot
                            </h3>
                            <p className='text-gray-600 font-medium leading-relaxed'>
                              Create an issue on Github and assign the issue to
                              Bugster.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='w-full p-5'>
                      <div className='flex flex-wrap -m-4'>
                        <div className='w-auto p-4'>
                          <div className='relative mb-3 w-16 h-16 text-lg text-indigo-600 font-bold border border-gray-200 rounded-full'>
                            <img
                              className='absolute top-0 left-0'
                              src='flaro-assets/images/how-it-works/gradient.svg'
                              alt=''
                            />
                            <span className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                              2
                            </span>
                          </div>
                        </div>
                        <div className='flex-1 p-4'>
                          <div className='md:max-w-xs'>
                            <h3 className='mb-3 text-xl font-semibold leading-normal'>
                              Review the Pull Request
                            </h3>
                            <p className='text-gray-600 font-medium leading-relaxed'>
                              Bugster will pull the relevant information, check
                              codebase and create a new branch with the fix and
                              submit a pull request.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='w-full p-5'>
                      <div className='flex flex-wrap -m-4'>
                        <div className='w-auto p-4'>
                          <div className='relative mb-3 w-16 h-16 text-lg text-indigo-600 font-bold border border-gray-200 rounded-full'>
                            <img
                              className='absolute top-0 left-0'
                              src='flaro-assets/images/how-it-works/gradient.svg'
                              alt=''
                            />
                            <span className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                              3
                            </span>
                          </div>
                        </div>
                        <div className='flex-1 p-4'>
                          <div className='md:max-w-xs'>
                            <h3 className='mb-3 text-xl font-semibold leading-normal'>
                              Merge solution to codebase
                            </h3>
                            <p className='text-gray-600 font-medium leading-relaxed'>
                              Review and verify the proposed solution suggested
                              by Bugster and merge the final solution to your
                              codebase.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='md:inline-block'></div>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-8 pt-32'>
                <img
                  className='mx-auto transform hover:translate-y-4 transition ease-in-out duration-1000'
                  src='imgs/demo.jpeg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </section>
        <section className='relative pt-24 pb-28 bg-white overflow-hidden'>
          <img
            className='absolute top-0 left-1/2 transform -translate-x-1/2'
            src='flaro-assets/images/cta/gradient4.svg'
            alt=''
          />
          <div className='relative z-10 container px-4 mx-auto'>
            <div className='flex flex-wrap -m-8'>
              <div className='w-full md:w-auto p-8'>
                <a href='#'>
                  <img
                    className='mx-auto transform hover:translate-y-4 transition ease-in-out duration-1000'
                    src='flaro-assets/images/cta/man-play.png'
                    alt=''
                  />
                </a>
              </div>
              <div className='w-full md:flex-1 p-8'>
                <div className='md:max-w-2xl mx-auto text-center'>
                  <h2 className='mb-10 text-6xl md:text-7xl font-bold font-heading text-center tracking-px-n leading-tight'>
                    Hard to believe? Join our Private beta
                  </h2>
                  <div className='mb-12 md:inline-block'>
                    <a href='https://ui53ay3p2dg.typeform.com/to/u1KPi7Cn'>

                      <button
                        className='py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200'
                        type='button'
                      >
                        Get Access Now
                      </button>
                    </a>

                  </div>
                  <div className='md:max-w-sm mx-auto'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <svg
                          className='mt-1'
                          width={26}
                          height={20}
                          viewBox='0 0 26 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        ></svg>
                      </div>
                      <div className='flex-1 p-2'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-auto self-end p-8'>
                <img
                  className='mx-auto transform hover:-translate-y-4 transition ease-in-out duration-1000'
                  src='flaro-assets/images/cta/woman-play2.png'
                  alt=''
                />
              </div>
            </div>
          </div>
        </section>
        <section className='pt-24 bg-white overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='flex flex-wrap justify-between items-center py-6 -m-4'>
              <div className='w-auto p-4'>
                <a href='#'>
                  <img className='' src='images/logo.png' alt='' style={{ height: '50px' }} />
                </a>
              </div>
              <div className='w-auto p-4'>
                <p className='text-sm text-gray-600 font-medium'>
                  Copyright © 2023 Bugster. All Rights Reserved
                </p>
              </div>
              <div className='w-auto p-4'>
                <div className='flex flex-wrap -m-4'>
                  <div className='w-auto p-4'>
                    <a
                      className='text-blueGray-400 hover:text-blueGray-500'
                      href='#'
                    >
                      <svg
                        width={16}
                        height={15}
                        viewBox='0 0 16 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      ></svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

